import { useState, useEffect } from 'react'
import xlsxFile from 'read-excel-file'
export default function useExcelParse() {
    const [excelFile, setExcelFile] = useState([])
    const [excelPage, setExcelPage] = useState([])
    const [errors, setErrors] = useState([])
    const [model, setModel] = useState()
    const [status, setStatus] = useState('notStarted')
    const [excelData, setExcelData] = useState([])

    const handleChange = (uploadedFile) => {
        setExcelFile([])
        setExcelPage([])
        setExcelData([])
        setImportResult([])
        setExcelFile([uploadedFile])
    }
    const [importResult, setImportResult] = useState([])
    const [loading, setLoading] = useState(false)

    const getExcelPage = (file) => {
        return xlsxFile(file, { getSheets: true }).then((rows) =>
            rows.filter(({ name }) => !name.startsWith('XX')).map((el) => ({ name: el.name, fileName: file.name }))
        )
    }

    const getAllPages = async () => {
        setLoading(true)
        const pages = await Promise.all(excelFile.map((file) => getExcelPage(file)))
        pages.map((page) => {
            page.map(({ name, fileName }) => {
                setExcelPage((prev) => [...prev, { name, fileName }])
            })
        })
        setLoading(false)
    }

    useEffect(() => {
        if (excelFile?.length > 0) {
            getAllPages()
        }
    }, [excelFile])

    useEffect(() => {
        if (excelPage.length > 0) {
            setLoading(true)

            excelPage.forEach((element, index) => {
                xlsxFile(
                    excelFile.find((el) => el.name === element.fileName),
                    { sheet: element.name }
                ).then((rows) => {
                    const values = rows.slice(3, rows.length)
                    const _excelData = []
                    const _errors = []
                    values.forEach((col) => {
                        // Read values
                        const productName = col[2]
                        const price = col[5]
                        const size = col[3]
                        const type = col[1]
                        const optionNb = col[4]

                        // Validate values
                        if (isNaN(price))
                            _errors.push(
                                `${productName} ${size} ${optionNb} do not contain a valid price in the Excel file (${price})`
                            )

                        // Remove duplicates
                        const isObjectDuplicate = excelData.find(
                            (obj) =>
                                productName === obj.productName &&
                                price === obj.price &&
                                size === obj.size &&
                                type === obj.type &&
                                optionNb === obj.optionNb
                        )
                        if (!isObjectDuplicate) {
                            _excelData.push({ productName, priceExcel: price, size, type, optionNb })
                        }
                    })
                    setExcelData((prev) => [...prev, ..._excelData])
                    setErrors(_errors)
                    setLoading(false)
                    setStatus('finished')
                })
            })
        }
    }, [excelPage])

    return { handleChange, importResult, loading, excelPage, excelData, setModel, status, errors }
}
