import Constants from '../constants'
import { apiUrl } from '../../environments/environments'

function UpdatePrice(Price, callback) {
    fetch(apiUrl + '/Prices/' + Price.id, {
        method: 'PUT',
        body: JSON.stringify(Price),
        headers: Constants.ApiHeader(),
    })
        .then((res) => res.json())
        .then((json) => {
            callback(json.result)
        })
}
function UpdatePricesList(name, prices) {
    return fetch(`${apiUrl}/Products/${name}/Prices`, {
        method: 'PUT',
        body: JSON.stringify(prices),
        headers: Constants.ApiHeader(),
    })
        .then((res) => {
            return res.json()
        })
        .then((res) => res.result)
        .catch((error) => {
            console.error('Error fetching product options:', error)
        })
}
function UpdatePrices(prices) {
    return fetch(`${apiUrl}/Prices`, {
        method: 'PUT',
        body: JSON.stringify(prices),
        headers: Constants.ApiHeader(),
    })
        .then((res) => {
            return res.json()
        })
        .then((res) => res.result)
        .catch((error) => {
            console.error('Error saving the prices:', error)
        })
}
export { UpdatePrice, UpdatePricesList, UpdatePrices }
