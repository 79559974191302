import React, { useEffect, useState } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Typography,
    Button,
    CircularProgress,
    Snackbar,
} from '@material-ui/core'
import { GetProductsOptions } from '../../../../assets/interfaces/productInterface'
import { UpdatePrices } from '../../../../assets/interfaces/priceInterface'
import ImportIcon from '@material-ui/icons/CloudUpload'

const StepComparePrices = ({ validatedProducts, products }) => {
    const [prices, setPrices] = useState([])
    const priceIdSet = new Set()
    const [loading, setLoading] = useState(false)
    const [resultMessage, setResultMessage] = useState(null)
    const handleUpdatePricesClick = () => {
        setLoading(true)
        const filteredPrices = prices
            .filter((price) => {
                if (price.priceExcel && price.priceId && price.priceDb !== price.priceExcel) {
                    if (!priceIdSet.has(price.priceId)) {
                        priceIdSet.add(price.priceId)
                        return true
                    }
                }
                return false
            })
            .map((price) => {
                return { id: price.priceId, Price: price.priceExcel }
            })
        UpdatePrices(filteredPrices)
            .then((updatedPrices) => {
                setResultMessage('Prices updated successfully')
            })
            .catch((error) => {
                setResultMessage(`Error updating prices: ${error}`)
            })
            .finally(() => {
                setLoading(false)
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            })
    }
    const filteredProducts = (async () => {
        const uniqueProductNames = Array.from(
            new Set(
                products
                    .filter(({ productName }) => validatedProducts.includes(productName))
                    .map(({ productName }) => productName)
            )
        )
        const productPricesMap = new Map()
        await Promise.all(
            uniqueProductNames.map(async (productName) => {
                const prices = await GetProductsOptions(productName)
                productPricesMap.set(productName, prices)
            })
        )
        const filteredArray = await Promise.all(
            products
                .filter(
                    ({ type, productName }) =>
                        (type === 'Base unit' || type === 'Option' || type === 'Packing') &&
                        validatedProducts.includes(productName)
                )
                .map(async ({ productName, size, priceExcel, type, optionNb }, index) => {
                    const productPrice = productPricesMap.get(productName)
                    const priceDb = productPrice?.find((price) => {
                        if (price.Size && (type === 'Base unit' || price.Number)) {
                            return (
                                price.Size.toString() === size.toString() &&
                                (type === 'Base unit' || optionNb.toString() === price.Number.toString())
                            )
                        }
                        return false
                    })
                    return {
                        name: productName,
                        size,
                        type,
                        priceExcel,
                        optionNb,
                        priceDb: priceDb ? priceDb.Price : null,
                        priceId: priceDb ? priceDb.id : null,
                        isCompatible: priceDb ? priceDb.Compatible : false,
                    }
                })
        )
        return filteredArray
    })()
    useEffect(() => {
        filteredProducts.then((result) => {
            const uniquePrices = new Set()
            const filteredResult = result.filter((price) => {
                if (
                    !uniquePrices.has(price.priceId) &&
                    price.priceDb !== price.priceExcel &&
                    price.isCompatible === true
                ) {
                    uniquePrices.add(price.priceId)
                    return true
                }
                return false
            })
            const filteredResultArray = Array.from(filteredResult)
            setPrices(filteredResultArray)
        })
    }, [])

    function calculatePriceDifference(nameFilter) {
        const filteredItems = prices && prices.filter((item) => item.name === nameFilter)
        const count = filteredItems.reduce((acc, item) => {
            if (item.priceExcel && item.priceDb !== item.priceExcel) {
                return acc + 1
            }
            return acc
        }, 0)

        return count
    }

    return (
        <>
            <TableContainer style={{ maxHeight: '500px', overflowX: 'auto', display: 'flex', flexDirection: 'column' }}>
                <Box style={{ flex: '1' }}>
                    <Typography variant='h6' component='h2'>
                        Prices of the following machine{validatedProducts.length > 1 && 's'} will be updated
                    </Typography>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant='h6' component='h2'>
                                        Product Name{validatedProducts.length > 1 && 's'}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='h6' component='h2'>
                                        Status in the database
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {validatedProducts.map((product, index) => (
                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component='th' scope='row'>
                                        <Typography variant='h6' component='h2'>
                                            {product}
                                        </Typography>
                                    </TableCell>
                                    <TableCell component='th' scope='row'>
                                        <Typography variant='h6' component='h2'>
                                            {calculatePriceDifference(product)} Price
                                            {calculatePriceDifference(product) > 1 && 's'} will be updated
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Snackbar
                        open={!!resultMessage}
                        autoHideDuration={3000}
                        onClose={() => setResultMessage(null)}
                        message={resultMessage}
                    />
                </Box>
                <Box display='flex' justifyContent='center' m={2}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Button
                            onClick={handleUpdatePricesClick}
                            component='label'
                            color='primary'
                            variant='outlined'
                            size='large'
                            startIcon={<ImportIcon />}>
                            Update prices
                        </Button>
                    )}
                </Box>
            </TableContainer>
        </>
    )
}

export default StepComparePrices
