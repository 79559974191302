import React, { useState } from 'react'
import { Box, Button, LinearProgress, Step, Stepper, Tooltip } from '@material-ui/core'
import StepUploadFile from './updatingSteps/stepUploadFile'
import StepDisplayProducts from './updatingSteps/stepDisplayProducts'
import StepComparePrices from './updatingSteps/stepComparePrices'
import useExcelParse from './useExcelParse'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
function PricesUpdate({ setIsModalOpen }) {
    const { handleChange, loading, excelData, errors, status } = useExcelParse()
    const [currentStep, setCurrentStep] = useState(1)
    const [validatedProducts, setValidatedProducts] = useState([])
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 1,
    }
    const steps = ['Upload Excel File', 'Products Mapping', 'Compare Prices and Update']

    const renderCurrentStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <StepUploadFile
                        onFileUpload={handleChange}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        errors={errors}
                        status={status}
                    />
                )
            case 2:
                return (
                    <StepDisplayProducts
                        products={excelData}
                        setValidatedProducts={setValidatedProducts}
                        validatedProducts={validatedProducts}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                    />
                )
            case 3:
                return <StepComparePrices validatedProducts={validatedProducts} products={excelData} />
            default:
                return null
        }
    }
    const isStepDisabled = (index) => {
        if ((index === 2 && !excelData.length) || (index === 3 && !validatedProducts.length)) {
            return true
        }
        return false
    }
    return (
        <Box sx={{ ...style, width: '70%', height: '70%' }} style={{ display: 'flex' }}>
            <Box style={{ flex: 1 }} height='100vh' alignItems='center' justifyContent='center'>
                <Stepper activeStep={currentStep - 1} orientation='vertical'>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <Tooltip title={label} placement='right'>
                                <Button
                                    variant='contained'
                                    color={currentStep === index + 1 ? 'primary' : 'default'}
                                    onClick={() => setCurrentStep(index + 1)}
                                    disabled={errors.length > 0 || isStepDisabled(index + 1)}>
                                    {index + 1}. {label}
                                </Button>
                            </Tooltip>
                        </Step>
                    ))}
                </Stepper>
            </Box>

            {loading === true ? <LinearProgress style={{ flex: 1 }} /> : renderCurrentStep()}
            <IconButton
                style={{ alignSelf: 'flex-start' }}
                onClick={() => {
                    setIsModalOpen(false)
                }}>
                <CloseIcon />
            </IconButton>
        </Box>
    )
}

export default PricesUpdate
